import React, { useState } from 'react'
import classNames from 'classnames'
import style from './Button.scss'

import { Icon } from '../Icon'

import type { IconType } from '../Icon'
import type { ColorTokenType } from 'src/components/colorTokenType'

export type ButtonSizeType = 'large-block' | 'large' | 'medium' | 'small'
export type ButtonFillType = 'brand' | 'danger'
export type ButtonSoftType = 'brand' | 'mono'

export type ButtonProps = {
  label: string
  size: ButtonSizeType
  fill?: ButtonFillType
  soft?: ButtonSoftType
  disabled?: boolean
  loading?: boolean
  width?: string
  fullWidth?: boolean
  halfWidth?: boolean
  leftIconName?: IconType
  rightIconName?: IconType
} & React.HTMLAttributes<HTMLButtonElement>

export function Button({
  label,
  size,
  fill,
  soft,
  disabled,
  width,
  fullWidth,
  halfWidth,
  loading,
  leftIconName,
  rightIconName,
  onTouchStart,
  onTouchEnd,
  className,
  ...props
}: ButtonProps) {
  const [isActive, setIsActive] = useState<boolean>(false)

  const classNameProps = classNames(
    style.button,
    style[size],
    style[`fill_${fill}`],
    style[`soft_${soft}`],
    isActive && style.active,
    loading && style.loading,
    leftIconName && style.leftIcon,
    rightIconName && style.rightIcon,
    halfWidth && style['half-width'],
    fullWidth && style['full-width'],
    className,
  )

  const iconColor: ColorTokenType = disabled
    ? 'gray400'
    : fill
    ? fill === 'brand'
      ? 'onBrand400'
      : 'gray0'
    : soft === 'brand'
    ? 'onBrand100'
    : 'gray800'

  const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
    if (disabled || loading) return
    if (onTouchStart) onTouchStart(e)
    setIsActive(true)
  }

  const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
    if (disabled || loading) return
    if (onTouchEnd) onTouchEnd(e)
    setIsActive(false)
  }

  return (
    <button
      className={classNameProps}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      disabled={disabled || loading}
      style={{ width, minWidth: width }}
      {...props}
    >
      {loading ? (
        <Icon name="LineSpinner" color="brand500" />
      ) : (
        <>
          {leftIconName && <Icon name={leftIconName} color={iconColor} />}
          {label}
          {rightIconName && <Icon name={rightIconName} color={iconColor} />}
        </>
      )}
    </button>
  )
}

export function ButtonFillBrand(props: Omit<ButtonProps, 'fill' | 'soft'>) {
  return <Button fill="brand" {...props} />
}

export function ButtonFillDanger(props: Omit<ButtonProps, 'fill' | 'soft'>) {
  return <Button fill="danger" {...props} />
}

export function ButtonSoftBrand(props: Omit<ButtonProps, 'fill' | 'soft'>) {
  return <Button soft="brand" {...props} />
}

export function ButtonSoftMono(props: Omit<ButtonProps, 'fill' | 'soft'>) {
  return <Button soft="mono" {...props} />
}
