import React, { useState } from 'react'
import classNames from 'classnames'
import style from './TextLink.scss'

import { Icon } from '../Icon'
import { Typo } from '../Typo'

import type { ColorTokenType } from 'src/components/colorTokenType'

export type TextLinkFontSize = 'h3' | 'p1' | 'c1'

export type TextLinkProps = {
  fontSize: TextLinkFontSize
  text: string
  brand?: boolean
  color?: ColorTokenType
} & React.HTMLAttributes<HTMLAnchorElement>

export function TextLink({
  fontSize,
  children,
  text,
  onTouchStart,
  onTouchEnd,
  brand,
  color = 'gray600',
  className,
  ...props
}: TextLinkProps) {
  const [isActive, setIsActive] = useState<boolean>(false)

  const classNameProps = classNames(
    style['text-link'],
    isActive && style.active,
    className,
  )

  const handleTouchStart = (e: React.TouchEvent<HTMLAnchorElement>) => {
    if (onTouchStart) onTouchStart(e)
    setIsActive(true)
  }

  const handleTouchEnd = (e: React.TouchEvent<HTMLAnchorElement>) => {
    if (onTouchEnd) onTouchEnd(e)
    setIsActive(false)
  }

  return (
    <a
      className={classNameProps}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      {...props}
    >
      <Typo
        fontSize={fontSize}
        fontWeight="medium"
        text={text}
        color={brand ? 'brand600' : color}
      />
      <Icon name="LineArrowRight" color={brand ? 'brand600' : color} />
    </a>
  )
}
