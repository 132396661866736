import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])

  return (
    <Svg size={size}>
      <path
        d="M16 3H4V16"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7H20V19C20 20.1046 19.1046 21 18 21H10C8.89543 21 8 20.1046 8 19V7Z"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
