import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])

  return (
    <Svg size={size}>
      <path
        d="M18 11C18 9.05791 17.827 6.50386 17.4955 4.40625C17.3871 3.71983 16.6124 3.39769 16.0276 3.77306L11.4937 6.68313C11.1714 6.89002 10.7964 7 10.4134 7H6C4.89543 7 4 7.89543 4 9V13C4 14.1046 4.89543 15 6 15H10.4134C10.7964 15 11.1714 15.11 11.4937 15.3169L16.0276 18.2269C16.6124 18.6023 17.3871 18.2802 17.4956 17.5937C17.827 15.4961 18 12.9421 18 11Z"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M20.8767 8C20.9583 8.9892 20.9999 9.98974 20.9999 11C20.9999 12.0103 20.9583 13.0108 20.8767 14"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="9"
        y1="7"
        x2="9"
        y2="21"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  )
}
