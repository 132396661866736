import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])

  return (
    <Svg size={size}>
      <circle
        cx="12"
        cy="7"
        r="3"
        className={strokeClassName}
        strokeWidth="2"
      />
      <path
        d="M11.3414 20H6C4.89543 20 4 19.1046 4 18C4 15.7909 5.79086 14 8 14H12.5278"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 20L21 17M21 17L18 14M21 17H14"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
