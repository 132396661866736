import React from 'react'
import classNames from 'classnames'
import style from './Dimmer.scss'

export type DimmerProps = {
  children: React.ReactNode
  vertical: 'top' | 'center' | 'bottom'
  horizontal?: 'left' | 'middle' | 'right'
  fadeOut?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export function Dimmer({
  children,
  vertical,
  horizontal,
  fadeOut,
  className,
  ...props
}: DimmerProps) {
  const classNameProps = classNames(
    style.dimmer,
    style[vertical],
    horizontal ? style[horizontal] : style.middle,
    fadeOut && style['fade-out'],
    className,
  )

  return (
    <div className={classNameProps} {...props}>
      {children}
    </div>
  )
}
