import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import fill from '../../fill.module.scss'
import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])
  const fillClassName = classNames(fill[color])

  return (
    <Svg size={size}>
      <circle
        cx="12"
        cy="12"
        r="9"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9.5C10.5 10.3284 9.82843 11 9 11C8.17157 11 7.5 10.3284 7.5 9.5C7.5 8.67157 8.17157 8 9 8C9.82843 8 10.5 8.67157 10.5 9.5Z"
        className={fillClassName}
      />
      <path
        d="M16.5 9.5C16.5 10.3284 15.8284 11 15 11C14.1716 11 13.5 10.3284 13.5 9.5C13.5 8.67157 14.1716 8 15 8C15.8284 8 16.5 8.67157 16.5 9.5Z"
        className={fillClassName}
      />
      <path
        d="M15.4649 14C14.7733 15.1956 13.4806 16 12 16C10.5195 16 9.22678 15.1956 8.53516 14"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}
