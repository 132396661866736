import React from 'react'
import * as svg from './svg/index'

import type { SvgProps } from './svg/Svg'

export type IconType = keyof typeof svg

export type IconProps = {
  name: IconType
} & SvgProps &
  React.HTMLAttributes<HTMLOrSVGElement>

export function Icon({
  name,
  color = 'gray800',
  size = 'small',
  ...props
}: IconProps) {
  return React.createElement(svg[name], { color, size, ...props })
}
