import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import style from './SelectBox.scss'

import { Icon } from '../Icon'
import { TypoC1, TypoP1 } from '../Typo'
import { CheckOptionBottomSheet } from '../BottomSheet'

export type SelectBoxProps = {
  label: string
  value?: string
  onSelectBoxClick: () => void
  BottomSheet: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export function SelectBox({
  label,
  value,
  onSelectBoxClick,
  BottomSheet,
  className,
  ...props
}: SelectBoxProps) {
  const classNameProps = classNames(style['select-box'], className)

  return (
    <>
      <div className={classNameProps} {...props}>
        {value && (
          <label htmlFor={label}>
            <TypoC1 text={label} fontWeight="medium" color="gray600" />
          </label>
        )}

        <button id={label} onClick={onSelectBoxClick}>
          <TypoP1
            text={value || label}
            fontWeight="medium"
            color={value ? 'gray800' : 'gray500'}
          />

          <Icon name="LineArrowDown" color="gray600" />
        </button>
      </div>

      {BottomSheet}
    </>
  )
}

export type CheckOptionSelectBoxProps = Omit<
  SelectBoxProps,
  'selectedOption' | 'onSelectBoxClick' | 'BottomSheet'
> & {
  optionTitle: string
  openOption?: boolean
  optionList: {
    option: string
    value: string
  }[]
  onOptionClick: (optionValue: string) => void
  optionChecked?: boolean
  optionDisabled?: boolean
}

export function CheckOptionSelectBox({
  label,
  optionTitle,
  openOption,
  optionList,
  onOptionClick,
  optionChecked,
  optionDisabled,
  ...props
}: CheckOptionSelectBoxProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>()

  useEffect(() => {
    if (openOption) setIsOpen(true)
  }, [openOption])

  return (
    <SelectBox
      label={label}
      value={selectedOption}
      onSelectBoxClick={() => setIsOpen(true)}
      BottomSheet={
        <CheckOptionBottomSheet
          open={isOpen}
          title={optionTitle}
          optionList={optionList}
          optionDisabled={optionDisabled}
          optionChecked={optionChecked}
          onClose={() => setIsOpen(false)}
          onOptionClick={(option, value) => {
            setSelectedOption(option)
            setIsOpen(false)
            onOptionClick(value)
          }}
        />
      }
      {...props}
    />
  )
}
