import React from 'react'
import classNames from 'classnames'
import style from './Modal.scss'

import { Dimmer } from '../Dimmer'
import { TypoH3, TypoP1 } from '../Typo'
import { Button } from '../Button'
import { Portal } from '../Portal'

import type { ButtonProps, ButtonFillType, ButtonSoftType } from '../Button'

export type ModalPrimaryPropsType = Omit<
  ButtonProps,
  'size' | 'width' | 'soft' | 'fullWidth' | 'halfWidth'
> & {
  fill: ButtonFillType
}

export type ModalSecondaryPropsType = Omit<
  ButtonProps,
  'size' | 'width' | 'fill' | 'disabled' | 'loading' | 'fullWidth' | 'halfWidth'
> & {
  soft: ButtonSoftType
}

export type ModalProps = {
  open: boolean
  title: string
  description?: string
  primaryProps: ModalPrimaryPropsType
  secondaryProps?: ModalSecondaryPropsType
  onClose: () => void
} & React.HTMLAttributes<HTMLDivElement>

// 버튼의 너비가 절반일 때 가능한 최대 레이블의 길이 (레이블의 길이는 띄어쓰기 제외 글자 길이로만 판단)
const MAX_HALF_LABEL_LENGTH = 4

export function Modal({
  open,
  title,
  description,
  primaryProps,
  secondaryProps,
  onClose,
  className,
  ...props
}: ModalProps) {
  const isFullWidth =
    !secondaryProps ||
    primaryProps.label.replaceAll(' ', '').length > MAX_HALF_LABEL_LENGTH ||
    (secondaryProps &&
      secondaryProps.label.replaceAll(' ', '').length > MAX_HALF_LABEL_LENGTH)

  const classNameProps = classNames(
    style.modal,
    isFullWidth && style.vertical,
    className,
  )

  const PrimaryButton = (
    <Button
      fullWidth={isFullWidth}
      halfWidth={!isFullWidth}
      size="large"
      {...primaryProps}
    />
  )

  const SecondaryButton = secondaryProps ? (
    <Button
      fullWidth={isFullWidth}
      halfWidth={!isFullWidth}
      size="large"
      {...secondaryProps}
    />
  ) : null

  if (!open) return null

  return (
    <Portal selector="#modal">
      <Dimmer vertical="center" onClick={onClose}>
        <div
          className={classNameProps}
          onClick={(e) => e.stopPropagation()}
          {...props}
        >
          <header>
            <TypoH3 text={title} fontWeight="bold" />
          </header>
          {description && (
            <div>
              <TypoP1 text={description} color="gray600" />
            </div>
          )}
          <footer>
            {isFullWidth ? (
              <>
                {PrimaryButton}
                {SecondaryButton}
              </>
            ) : (
              <>
                {SecondaryButton}
                {PrimaryButton}
              </>
            )}
          </footer>
        </div>
      </Dimmer>
    </Portal>
  )
}
