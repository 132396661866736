import React, { useState } from 'react'
import classNames from 'classnames'
import style from './ListRow.scss'

import { TypoP1 } from '../Typo'
import { Icon } from '../Icon'

import type { IconType } from '../Icon'
import type { ColorTokenType } from 'src/components/colorTokenType'

export type ListRowProps = {
  UpTypo: React.ReactNode
  DownTypo?: React.ReactNode
  Left?: React.ReactNode
  Right?: React.ReactNode
  leftIconName?: IconType
  leftIconColor?: ColorTokenType
  rightIconName?: IconType
  rightIconColor?: ColorTokenType
  padding?: number
} & React.HTMLAttributes<HTMLLIElement>

export function ListRow({
  UpTypo,
  DownTypo,
  Left,
  Right,
  leftIconName,
  leftIconColor,
  rightIconName,
  rightIconColor,
  padding,
  onTouchStart,
  onTouchEnd,
  onClick,
  className,
  ...props
}: ListRowProps) {
  const [isActive, setIsActive] = useState<boolean>(false)

  const classNameProps = classNames(
    style['list-row'],
    DownTypo && style['down-text'],
    isActive && style.active,
    onClick && style.cursor,
    className,
  )

  const handleTouchStart = (e: React.TouchEvent<HTMLLIElement>) => {
    if (!onClick) return
    if (onTouchStart) onTouchStart(e)
    setIsActive(true)
  }

  const handleTouchEnd = (e: React.TouchEvent<HTMLLIElement>) => {
    if (!onClick) return
    if (onTouchEnd) onTouchEnd(e)
    setIsActive(false)
  }

  return (
    <li
      className={classNameProps}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={
        padding ? { paddingTop: padding, paddingBottom: padding } : undefined
      }
      onClick={onClick}
      {...props}
    >
      {leftIconName && <Icon name={leftIconName} color={leftIconColor} />}
      {Left && <div>{Left}</div>}
      <div className={classNames(style['text-container'])}>
        {UpTypo}

        {DownTypo}
      </div>
      {rightIconName && <Icon name={rightIconName} color={rightIconColor} />}
      {Right && <div>{Right}</div>}
    </li>
  )
}

export type CheckOptionListRowProps = Omit<
  ListRowProps,
  | 'UpTypo'
  | 'DownTypo'
  | 'leftIconName'
  | 'leftIconColor'
  | 'rightIconName'
  | 'rightIconColor'
  | 'Left'
  | 'padding'
> & {
  text: string
  checked?: boolean
  disabled?: boolean
}

export function CheckOptionListRow({
  text,
  checked,
  disabled,
  onClick,
  ...props
}: CheckOptionListRowProps) {
  return (
    <ListRow
      className={checked ? 'check-option-list-row_icon' : undefined}
      UpTypo={<TypoP1 text={text} color={disabled ? 'gray400' : undefined} />}
      rightIconName={checked ? 'LineCheck' : undefined}
      onClick={disabled ? undefined : onClick}
      style={disabled ? { cursor: 'not-allowed' } : undefined}
      {...props}
    />
  )
}
