import React from 'react'
import classNames from 'classnames'
import style from './BottomCTA.scss'

import { Button, ButtonFillBrand } from '../Button'

import type { ButtonProps, ButtonSoftType } from '../Button'

export type BottomCTAPrimaryPropsType = Omit<
  ButtonProps,
  'size' | 'fill' | 'soft' | 'width' | 'fullWidth' | 'halfWidth'
>

export type BottomCTASecondaryPropsType = Omit<
  ButtonProps,
  'size' | 'fill' | 'disabled' | 'loading' | 'width' | 'fullWidth' | 'halfWidth'
> & {
  soft: ButtonSoftType
}

export type BottomCTAProps = {
  linear?: boolean
  primaryProps: BottomCTAPrimaryPropsType
  secondaryProps?: BottomCTASecondaryPropsType
} & React.HTMLAttributes<HTMLDivElement>

// 버튼의 너비가 절반일 때 가능한 최대 레이블의 길이 (레이블의 길이는 띄어쓰기 제외 글자 길이로만 판단)
const MAX_HALF_LABEL_LENGTH = 6

export function BottomCTA({
  linear,
  primaryProps,
  secondaryProps,
  className,
  ...props
}: BottomCTAProps) {
  const isFullWidth =
    !secondaryProps ||
    primaryProps.label.replaceAll(' ', '').length > MAX_HALF_LABEL_LENGTH ||
    (secondaryProps &&
      secondaryProps.label.replaceAll(' ', '').length > MAX_HALF_LABEL_LENGTH)

  const classNameProps = classNames(
    style['bottom-cta'],
    linear && style.linear,
    isFullWidth && style.vertical,
    className,
  )

  const PrimaryButton = (
    <ButtonFillBrand
      fullWidth={isFullWidth}
      halfWidth={!isFullWidth}
      size="large"
      {...primaryProps}
    >
      {primaryProps.label}
    </ButtonFillBrand>
  )

  const SecondaryButton = secondaryProps ? (
    <Button
      fullWidth={isFullWidth}
      halfWidth={!isFullWidth}
      size="large"
      {...secondaryProps}
    >
      {secondaryProps.label}
    </Button>
  ) : null

  return (
    <div id="bottom-cta" className={classNameProps} {...props}>
      {isFullWidth ? (
        <>
          {PrimaryButton}
          {SecondaryButton}
        </>
      ) : (
        <>
          {SecondaryButton}
          {PrimaryButton}
        </>
      )}
    </div>
  )
}
