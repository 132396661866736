import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import style from './Snackbar.scss'

import { TypoC1 } from '../Typo'
import { TextButton } from '../TextButton'
import { Portal } from '../Portal'

let direction: 'left' | 'right' | undefined

export type SnackbarProps = {
  open: boolean
  firstLine: string
  secondLine?: string
  buttonLabel?: string
  onButtonClick?: () => void
  onClose: () => void
} & React.HTMLAttributes<HTMLDivElement>

// 버튼의 레이블이 길면 스낵바의 형태가 달라지는데 짧음의 기준이 되는 레이블 길이 (레이블의 길이는 띄어쓰기 제외 글자 길이로만 판단)
const MAX_SHORT_LABEL_LENGTH = 6

export function Snackbar({
  open,
  firstLine,
  secondLine,
  buttonLabel,
  onButtonClick,
  onClose,
  className,
  ...props
}: SnackbarProps) {
  const isLongButtonLabel =
    buttonLabel &&
    buttonLabel.replaceAll(' ', '').length > MAX_SHORT_LABEL_LENGTH

  const classNameProps = classNames(
    style.snackbar,
    isLongButtonLabel && style['long-button'],
    open && style.open,
    !open && style.close,
    direction === 'left' && style['left-direction'],
    direction === 'right' && style['right-direction'],
    className,
  )

  const [dragX, setDragX] = useState<number>(0)
  const [positionX, setPositionX] = useState<number>(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      direction = undefined
      onClose()
    }, 6000)

    if (open) timeout

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const startDragX = e.changedTouches[0].clientX
    setDragX(startDragX)
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const positionX = e.changedTouches[0].clientX
    setPositionX(positionX - dragX)
  }

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    const endDragX = e.changedTouches[0].clientX

    if (dragX - endDragX > 80) {
      direction = 'left'
      onClose()
    } else if (endDragX - dragX > 80) {
      direction = 'right'
      onClose()
    } else {
      setTimeout(() => {
        setPositionX(0)
      }, 100)
    }
  }

  return (
    <Portal selector="#modal">
      <div
        className={classNameProps}
        style={{
          marginLeft: positionX,
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        {...props}
      >
        <div>
          <TypoC1 text={firstLine} color="gray0" />
          {secondLine && <TypoC1 text={secondLine} color="gray0" />}
        </div>
        {buttonLabel && (
          <TextButton
            className="snackbar_text-button"
            label={buttonLabel}
            fontSize="c2"
            onClick={onButtonClick}
          />
        )}
      </div>
    </Portal>
  )
}
