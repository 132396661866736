import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import fill from '../../fill.module.scss'
import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])
  const fillClassName = classNames(fill[color])

  return (
    <Svg size={size}>
      <path
        d="M15 10L14.0136 9.8356C13.9653 10.1256 14.047 10.4221 14.237 10.6464C14.427 10.8707 14.706 11 15 11V10ZM4 10V9C3.44772 9 3 9.44772 3 10H4ZM20.5216 12.3922L21.5021 12.5884L20.5216 12.3922ZM6 21H17.3604V19H6V21ZM18.5604 9H15V11H18.5604V9ZM15.9864 10.1644L16.7923 5.3288L14.8195 5L14.0136 9.8356L15.9864 10.1644ZM14.8195 3H14.6056V5H14.8195V3ZM11.2773 4.7812L8.76168 8.5547L10.4258 9.6641L12.9415 5.8906L11.2773 4.7812ZM7.92963 9H4V11H7.92963V9ZM3 10V18H5V10H3ZM20.3021 18.5883L21.5021 12.5884L19.541 12.1961L18.341 18.1961L20.3021 18.5883ZM8.76168 8.5547C8.57622 8.8329 8.26399 9 7.92963 9V11C8.93269 11 9.86939 10.4987 10.4258 9.6641L8.76168 8.5547ZM16.7923 5.3288C16.9955 4.10973 16.0554 3 14.8195 3V5L14.8195 5L16.7923 5.3288ZM18.5604 11C19.1914 11 19.6647 11.5773 19.541 12.1961L21.5021 12.5884C21.8734 10.732 20.4535 9 18.5604 9V11ZM17.3604 21C18.7904 21 20.0217 19.9906 20.3021 18.5883L18.341 18.1961C18.2475 18.6635 17.8371 19 17.3604 19V21ZM14.6056 3C13.2681 3 12.0192 3.6684 11.2773 4.7812L12.9415 5.8906C13.3124 5.3342 13.9368 5 14.6056 5V3ZM6 19C5.44772 19 5 18.5523 5 18H3C3 19.6569 4.34315 21 6 21V19Z"
        className={fillClassName}
      />
      <path d="M8 10V20" className={strokeClassName} strokeWidth="2" />
    </Svg>
  )
}
