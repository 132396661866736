import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import style from './BottomSheet.scss'

import { Portal } from '../Portal'
import { Dimmer } from '../Dimmer'
import { TypoC1, TypoH3 } from '../Typo'
import { CheckOptionListRow } from '../ListRow'

export type BottomSheetProps = {
  open: boolean
  title: string
  subTitle?: string
  onClose: () => void
  BottomCTA?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export function BottomSheet({
  open,
  title,
  subTitle,
  onClose,
  BottomCTA,
  children,
  className,
  ...props
}: BottomSheetProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    if (open) setIsVisible(true)
    else if (!open && isVisible) {
      const timer = setTimeout(() => setIsVisible(false), 300)

      return () => clearTimeout(timer)
    }
  }, [open, isVisible])

  const classNameProps = classNames(
    style['bottom-sheet'],
    BottomCTA && style['bottom-cta'],
    open && style.open,
    className,
  )

  if (!isVisible) return null

  return (
    <Portal selector="#modal">
      <Dimmer vertical="bottom" onClick={onClose} fadeOut={!open}>
        <div
          className={classNameProps}
          onClick={(e) => e.stopPropagation()}
          {...props}
        >
          <header>
            <TypoH3 text={title} fontWeight="bold" />
            {subTitle && (
              <TypoC1
                text={subTitle}
                color="gray600"
                style={{ marginTop: 8 }}
              />
            )}
          </header>
          <div>
            {subTitle && (
              <div className={style.line}>
                <div />
              </div>
            )}
            {children}
          </div>
          {BottomCTA}
        </div>
      </Dimmer>
    </Portal>
  )
}

export type CheckOptionBottomSheetProps = BottomSheetProps & {
  optionChecked?: boolean
  optionDisabled?: boolean
  optionList: {
    option: string
    value: string
  }[]
  onOptionClick: (option: string, value: string) => void
}

export function CheckOptionBottomSheet({
  open,
  optionChecked,
  optionDisabled,
  title,
  optionList,
  onClose,
  onOptionClick,
}: CheckOptionBottomSheetProps) {
  return (
    <BottomSheet open={open} title={title} onClose={onClose}>
      {optionList.map(({ option, value }) => (
        <CheckOptionListRow
          key={value}
          text={option}
          checked={optionChecked}
          disabled={optionDisabled}
          onClick={() => onOptionClick(option, value)}
        />
      ))}
    </BottomSheet>
  )
}
