import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])

  return (
    <Svg size={size}>
      <path
        d="M13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19Z"
        className={strokeClassName}
        strokeWidth="2"
      />
      <path
        d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
        className={strokeClassName}
        strokeWidth="2"
      />
      <path
        d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5Z"
        className={strokeClassName}
        strokeWidth="2"
      />
    </Svg>
  )
}
