import React, { forwardRef } from 'react'
import classNames from 'classnames'
import style from './MainContainer.scss'

export type MainContainerProps = React.HTMLAttributes<HTMLDivElement>

const MainContainer = forwardRef<HTMLDivElement, MainContainerProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref} className={classNames(style['main-container'])} {...props}>
      {children}
    </div>
  ),
)

export { MainContainer }
