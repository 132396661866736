import React, { useState } from 'react'
import classNames from 'classnames'
import style from './TextButton.scss'

import { Typo } from '../Typo'

import type { FontWeightType } from '../Typo'
import type { ColorTokenType } from 'src/components/colorTokenType'

export type TextButtonFontSizeType = 'p1' | 'c1' | 'c2'

export type TextButtonProps = {
  label: string
  fontSize: TextButtonFontSizeType
  fontWeight?: FontWeightType
  disabled?: boolean
  brand?: boolean
  color?: ColorTokenType
} & React.HTMLAttributes<HTMLButtonElement>

export function TextButton({
  label,
  fontSize,
  fontWeight = 'bold',
  disabled,
  brand,
  color,
  onTouchStart,
  onTouchEnd,
  className,
  ...props
}: TextButtonProps) {
  const [isActive, setIsActive] = useState<boolean>(false)

  const classNameProps = classNames(
    style['text-button'],
    isActive && style.active,
    className,
  )

  const handleTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
    if (disabled) return
    if (onTouchStart) onTouchStart(e)
    setIsActive(true)
  }

  const handleTouchEnd = (e: React.TouchEvent<HTMLButtonElement>) => {
    if (disabled) return
    if (onTouchEnd) onTouchEnd(e)
    setIsActive(false)
  }

  return (
    <button
      className={classNameProps}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      disabled={disabled}
      {...props}
    >
      <Typo
        text={label}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={brand ? 'brand600' : color}
      />
    </button>
  )
}
