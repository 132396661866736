import React from 'react'
import classNames from 'classnames'
import style from './Typo.scss'

import type { ColorTokenType } from 'src/components/colorTokenType'

export type FontSizeType = 'h1' | 'h2' | 'h3' | 'p1' | 'c1' | 'c2'
export type FontWeightType = 'bold' | 'medium' | 'regular'

export type TypoProps = {
  text: string
  fontSize: FontSizeType
  fontWeight?: FontWeightType
  color?: ColorTokenType
  display?: 'inline' | 'block'
} & React.HTMLAttributes<HTMLParagraphElement>

export function Typo({
  text,
  fontSize,
  fontWeight = 'regular',
  color = 'gray800',
  display = 'block',
  className,
  ...props
}: TypoProps) {
  const classNameProps = classNames(
    style.typo,
    style[fontSize],
    style[fontWeight],
    style[display],
    style[color],
    className,
  )

  return (
    <p className={classNameProps} {...props}>
      {text}
    </p>
  )
}

export function TypoH1(props: Omit<TypoProps, 'fontSize'>) {
  return <Typo fontSize="h1" {...props} />
}

export function TypoH2(props: Omit<TypoProps, 'fontSize'>) {
  return <Typo fontSize="h2" {...props} />
}

export function TypoH3(props: Omit<TypoProps, 'fontSize'>) {
  return <Typo fontSize="h3" {...props} />
}

export function TypoP1(props: Omit<TypoProps, 'fontSize'>) {
  return <Typo fontSize="p1" {...props} />
}

export function TypoC1(props: Omit<TypoProps, 'fontSize'>) {
  return <Typo fontSize="c1" {...props} />
}

export function TypoC2(props: Omit<TypoProps, 'fontSize'>) {
  return <Typo fontSize="c2" {...props} />
}
