import React from 'react'
import classNames from 'classnames'
import style from './svg.module.scss'

import type { ColorTokenType } from 'src/components/colorTokenType'

export type IconSizeType = 'large' | 'medium' | 'small' | 'xSmall'

export type SvgProps = {
  color?: ColorTokenType
  size?: IconSizeType
}

export default function Svg({
  color = 'gray800',
  size = 'small',
  children,
}: {
  color?: ColorTokenType
  size?: IconSizeType
  children: React.ReactNode
}) {
  const classNameProps = classNames(style[size], style[color])

  return (
    <svg
      className={classNameProps}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  )
}
