import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])

  return (
    <Svg size={size}>
      <path
        d="M6 19V10C6 6.68629 8.68629 4 12 4V4C15.3137 4 18 6.68629 18 10V19M6 19H18M6 19H4M18 19H20"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 22L14 22"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="3"
        r="1"
        className={strokeClassName}
        strokeWidth="2"
      />
    </Svg>
  )
}
