import React from 'react'
import Svg, { SvgProps } from '../../Svg'
import classNames from 'classnames'

import stroke from '../../stroke.module.scss'

export default function ({ color = 'gray800', size }: SvgProps) {
  const strokeClassName = classNames(stroke[color])

  return (
    <Svg size={size}>
      <path
        d="M16.6979 19.3832C19.1339 17.8301 20.75 15.1036 20.75 12C20.75 7.16737 16.8326 3.25 12 3.25C7.16738 3.25 3.25 7.16738 3.25 12C3.25 16.8326 7.16738 20.75 12 20.75"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.4848 16.8542L16.4848 19.8847L19.5152 19.8847"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.14307V10.0716V12.0002H15"
        className={strokeClassName}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  )
}
