import React from 'react'
import classNames from 'classnames'
import style from './FAB.scss'

import { Portal } from '../Portal'
import { Icon } from '../Icon'

import type { IconType } from '../Icon'

export type FABTypeType = 'fill' | 'line'

export type FABProps = {
  type: FABTypeType
  iconName: IconType
} & React.HTMLAttributes<HTMLButtonElement>

export function FAB({ type, iconName, className, ...props }: FABProps) {
  const classNameProps = classNames(
    type === 'fill' ? 'FAB_fill' : 'FAB_line',
    style.FAB,
    style[type],
    className,
  )

  return (
    <Portal selector="#modal">
      <button className={classNameProps} {...props}>
        <Icon name={iconName} />
      </button>
    </Portal>
  )
}
