import React from 'react'
import classNames from 'classnames'
import style from './Top.scss'

import { Typo } from '../Typo'

import type { FontSizeType, FontWeightType } from '../Typo'
import type { ColorTokenType } from 'src/components/colorTokenType'

export type TopProps = {
  subTitle?: string
  subTitleFont?: {
    fontSize?: FontSizeType
    fontWeight?: FontWeightType
    color?: ColorTokenType
  }
  titleFont?: {
    fontSize?: FontSizeType
    fontWeight?: FontWeightType
  }
  firstTitle: string
  firstTitle2?: string
  firstTitle3?: string
  secondTitle?: string
  secondTitle2?: string
  secondTitle3?: string
  thirdTitle?: string
  thirdTitle2?: string
  thirdTitle3?: string
  firstTitleBrand?: boolean
  firstTitle2Brand?: boolean
  firstTitle3Brand?: boolean
  secondTitleBrand?: boolean
  secondTitle2Brand?: boolean
  secondTitle3Brand?: boolean
  thirdTitleBrand?: boolean
  thirdTitle2Brand?: boolean
  thirdTitle3Brand?: boolean
  description?: string
  descriptionFont?: {
    fontSize?: FontSizeType
    fontWeight?: FontWeightType
    color?: ColorTokenType
  }
  paddingTop?: number
  paddingBottom?: number
} & React.HTMLAttributes<HTMLDivElement>

export function Top({
  subTitle,
  subTitleFont,
  titleFont,
  firstTitle,
  firstTitle2,
  firstTitle3,
  secondTitle,
  secondTitle2,
  secondTitle3,
  thirdTitle,
  thirdTitle2,
  thirdTitle3,
  firstTitleBrand,
  firstTitle2Brand,
  firstTitle3Brand,
  secondTitleBrand,
  secondTitle2Brand,
  secondTitle3Brand,
  thirdTitleBrand,
  thirdTitle2Brand,
  thirdTitle3Brand,
  description,
  descriptionFont,
  paddingTop = 16,
  paddingBottom = 16,
  className,
  ...props
}: TopProps) {
  const classNameProps = classNames(style.top, className)

  return (
    <div
      className={classNameProps}
      style={{ paddingTop, paddingBottom }}
      {...props}
    >
      {subTitle && (
        <Typo
          text={subTitle}
          fontSize={subTitleFont?.fontSize || 'p1'}
          fontWeight={subTitleFont?.fontWeight || 'bold'}
          color={subTitleFont?.color}
        />
      )}

      <div>
        <Typo
          text={firstTitle}
          fontSize={titleFont?.fontSize || 'h2'}
          fontWeight={titleFont?.fontWeight || 'bold'}
          className={firstTitleBrand ? 'top_title_brand' : undefined}
          display="inline"
        />
        {firstTitle2 && (
          <Typo
            text={firstTitle2}
            fontSize={titleFont?.fontSize || 'h2'}
            fontWeight={titleFont?.fontWeight || 'bold'}
            className={firstTitle2Brand ? 'top_title_brand' : undefined}
            display="inline"
          />
        )}
        {firstTitle3 && (
          <Typo
            text={firstTitle3}
            fontSize={titleFont?.fontSize || 'h2'}
            fontWeight={titleFont?.fontWeight || 'bold'}
            className={firstTitle3Brand ? 'top_title_brand' : undefined}
            display="inline"
          />
        )}
      </div>
      {secondTitle && (
        <div>
          {secondTitle && (
            <Typo
              text={secondTitle}
              fontSize={titleFont?.fontSize || 'h2'}
              fontWeight={titleFont?.fontWeight || 'bold'}
              className={secondTitleBrand ? 'top_title_brand' : undefined}
              display="inline"
            />
          )}
          {secondTitle2 && (
            <Typo
              text={secondTitle2}
              fontSize={titleFont?.fontSize || 'h2'}
              fontWeight={titleFont?.fontWeight || 'bold'}
              className={secondTitle2Brand ? 'top_title_brand' : undefined}
              display="inline"
            />
          )}
          {secondTitle3 && (
            <Typo
              text={secondTitle3}
              fontSize={titleFont?.fontSize || 'h2'}
              fontWeight={titleFont?.fontWeight || 'bold'}
              className={secondTitle3Brand ? 'top_title_brand' : undefined}
              display="inline"
            />
          )}
        </div>
      )}
      {thirdTitle && (
        <div>
          {thirdTitle && (
            <Typo
              text={thirdTitle}
              fontSize={titleFont?.fontSize || 'h2'}
              fontWeight={titleFont?.fontWeight || 'bold'}
              className={thirdTitleBrand ? 'top_title_brand' : undefined}
              display="inline"
            />
          )}
          {thirdTitle2 && (
            <Typo
              text={thirdTitle2}
              fontSize={titleFont?.fontSize || 'h2'}
              fontWeight={titleFont?.fontWeight || 'bold'}
              className={thirdTitle2Brand ? 'top_title_brand' : undefined}
              display="inline"
            />
          )}
          {thirdTitle3 && (
            <Typo
              text={thirdTitle3}
              fontSize={titleFont?.fontSize || 'h2'}
              fontWeight={titleFont?.fontWeight || 'bold'}
              className={thirdTitle3Brand ? 'top_title_brand' : undefined}
              display="inline"
            />
          )}
        </div>
      )}

      {description && (
        <Typo
          text={description}
          fontSize={descriptionFont?.fontSize || 'c1'}
          fontWeight={descriptionFont?.fontWeight}
          color={descriptionFont?.color || 'gray600'}
        />
      )}
    </div>
  )
}
