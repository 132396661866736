import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import style from './Accordion.scss'

import { Icon } from '../Icon'
import { TypoP1 } from '../Typo'

export type AccordionHeightType = '56' | '48'

export type AccordionProps = {
  title: string
  height?: AccordionHeightType
} & React.HTMLAttributes<HTMLDivElement>

export function Accordion({
  title,
  height = '56',
  children,
  className,
  ...props
}: AccordionProps) {
  const ref = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const classNameProps = classNames(
    style.accordion,
    height === '56' ? style.medium : style.small,
    isOpen && style.open,
    className,
  )

  return (
    <div className={classNameProps} {...props}>
      <div onClick={() => setIsOpen(!isOpen)}>
        <TypoP1 text={title} fontWeight="medium" />
        <Icon name="LineArrowDown" />
      </div>
      <div
        ref={ref}
        style={
          isOpen && ref.current
            ? {
                maxHeight: ref.current.scrollHeight + 24,
              }
            : undefined
        }
      >
        {children}
      </div>
    </div>
  )
}
