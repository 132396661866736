import React from 'react'
import classNames from 'classnames'
import style from './ToggleButton.scss'

export type ToggleButtonProps = {
  size: 'medium' | 'small'
  active: boolean
  onToggle: () => void
} & React.HTMLAttributes<HTMLButtonElement>

export function ToggleButton({
  size,
  active,
  onToggle,
  className,
  ...props
}: ToggleButtonProps) {
  const classNameProps = classNames(
    style['toggle-button'],
    style[size],
    active && 'toggle-button_active',
    active && style.active,
    className,
  )

  return (
    <button className={classNameProps} onClick={onToggle} {...props}>
      <div />
    </button>
  )
}
